<!--用户管理-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
       <!-- <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb  flex-wrap">
                <label>场景：</label>
                <el-input placeholder="姓名/手机号/昵称" v-model="select.keyword" size="small" style="width:350px;" @change="curr=1;getList()">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff"></el-button>
                </el-input>
				<el-button type="success" block size="mini">非会员：{{count}}个</el-button>
            </div>
        </div> -->

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 119px );margin-top: 20px;">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="realname" label="姓名" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="mobile" width="100" label="手机号" show-overflow-tooltip>
                </el-table-column>
				<el-table-column prop="nickname" label="昵称" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="头像" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.headimgurl" :src ="scope.row.headimgurl" class="tableImage" :preview-src-list="[scope.row.headimgurl]"></el-image>
                        <el-avatar v-else shape="square" :size="45" :src="require('../../assets/ava.png')"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="member.name" align="center" label="会员名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="end_time" align="center" label="到期时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" align="center" label="注册时间" width="160" show-overflow-tooltip></el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {remindListApi}  from '@/api/member.js';
    export default {
        data() {
            return {
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
				list:[]
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            //商品列表
            getList: function(){
                remindListApi({
					curr: this.curr,
					row: this.row,
				}).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
				
            },
            
          
        
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>